import moment from "moment";
import { Moment } from "moment";
import { useEffect, useState } from "react";

const useGetTotalValues = (loans: DTO.LoanType[]) => {
  const [date, setDate] = useState<Moment[]>([
    moment().clone().startOf("month"),
    moment(),
  ]);
  const [totleDefaulted, setTotalDefaulted] = useState<number>(0);
  const [totalInterestEx, setTotalInterestEx] = useState<number>(0);
  const [totalInterestPaid, setTotalInterestPaid] = useState<number>(0);
  const [totalAmountEx, setTotalAmountEx] = useState<number>(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState<number>(0);
  const todayDate = moment().format("MM/DD/YY");

  useEffect(() => {
    const allMonths = loans.map((loan) => loan.monthly_data).flat();
    getTotalInterestEx(allMonths);
    getTotalInterestPaid(allMonths);
    getTotalAmountEx(allMonths);
    getTotalAmountPaid(allMonths);
    getTotalDefault(allMonths);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loans, date]);

  const getTotalInterestEx = (allMonths: DTO.TenorType[]) => {
    let total = 0;
    const allPayedMonths = allMonths?.filter(
      ({ payed, monthly_date }) =>
      moment(monthly_date).isBetween(date?.[0], date?.[1])
    );

    allPayedMonths.forEach(({ monthly_interest }) => {
      total = total + Number(monthly_interest);
    });

    setTotalInterestEx(total);
  };

  const getTotalInterestPaid = (allMonths: DTO.TenorType[]) => {
    let total = 0;
    const allPayedMonths = allMonths?.filter(
      ({ payed, monthly_date }) =>
      payed && moment(monthly_date).isBetween(date?.[0], date?.[1])
    );

    allPayedMonths.forEach(({ monthly_interest }) => {
      total = total + Number(monthly_interest);
    });

    setTotalInterestPaid(total);
  };

  const getTotalAmountEx = (allMonths: DTO.TenorType[]) => {
    let total = 0;
    const allPayedMonths = allMonths?.filter(
      ({ monthly_date }) =>
        moment(monthly_date).isBetween(date?.[0], date?.[1])
    );

    allPayedMonths.forEach(({ monthly_capital, monthly_interest }) => {
      total = total + Number(monthly_capital) + Number(monthly_interest);
    });

    setTotalAmountEx(total);
  };

  const getTotalAmountPaid = (allMonths: DTO.TenorType[]) => {
    let total = 0;
    const allPayedMonths = allMonths?.filter(
      ({ payed, monthly_date }) =>
        payed && moment(monthly_date).isBetween(date?.[0], date?.[1])
    );

    allPayedMonths.forEach(({ monthly_capital, monthly_interest }) => {
      total = total + Number(monthly_capital) + Number(monthly_interest);
    });

    setTotalAmountPaid(total);
  };

  const getTotalDefault = (allMonths: DTO.TenorType[]) => {
    let total = 0;
    const allDefaultedMonths = allMonths?.filter(({ payed, monthly_date }) => {
      const isDefaultedAndInDaterange =
        !payed &&
        moment(monthly_date).diff(todayDate, "days") < 0 &&
        moment(monthly_date).isBetween(date?.[0], date?.[1]);
      return isDefaultedAndInDaterange;
    });

    allDefaultedMonths.forEach(({ monthly_capital, monthly_interest }) => {
      total = total + Number(monthly_capital) + Number(monthly_interest);
    });

    setTotalDefaulted(total);
  };

  const updateDate = (date: Moment[]) => setDate(date);

  return {
    totalAmountEx,
    totalAmountPaid,
    totalInterestEx,
    totalInterestPaid,
    totleDefaulted,
    updateDate,
  };
};

export { useGetTotalValues };
