import React, { FC } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

interface SidebarListItemProps {
  icon?: JSX.Element,
  title: string,
  isActive: boolean,
  id: string,
  handleSignout: () => void,
}

const SidebarListItemWrap = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 8px;
  height: 45px;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.blue4};

  .anticon {
    font-size: 1.25rem;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.blue3};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.blue1};
    color: ${({ theme }) => theme.colors.white};

    .anticon {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  p {
    margin: 0;
  }

  .sidebar-icon-wrap {
    display: flex;
    align-items: center;
  }
  
`

const SidebarListItem: FC<SidebarListItemProps> = ({
  handleSignout,
  icon,
  isActive,
  title,
  id,
}) => {
  const history = useHistory()
  let { url } = useRouteMatch();

  const handleClick = () => {
    if (id === "signout") handleSignout()
    if (id) {
      history.push(`${url}/${id}`)
      return
    }
     history.push(url)
   }
   
  return (
    <SidebarListItemWrap className={`${isActive && 'active'}`} onClick={() => handleClick()}>
      <div className='sidebar-icon-wrap'>
        {icon}
      </div>

      <p className="sidebar-text-wrap">{title}</p>
    </SidebarListItemWrap>
  )
};

export { SidebarListItem };
