import {
  CheckCircleOutlined,
  CheckOutlined,
  CoffeeOutlined,
  DeleteOutlined,
  DownOutlined,
  HourglassOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Popconfirm } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import _ from "lodash";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { getSingleMonthStat } from "../../helpers";
import { handleDeleteLoan } from "../../helpers/requests";
import FilterPanael from "./FilterPanel";
import { StyledDashboardTtitle } from "./LoanForm";

interface LoanListPageProps {
  loans: DTO.LoanType[];
  isMobile: boolean;
  loading: boolean;
  handleTriggerLoanFetch: () => void;
}

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};

  hr {
    margin: 0 !important;
  }

  .filter-toggle {
    width: fit-content;
    background: ${({ theme }) => theme.colors.blue};
    color: white;
    margin: 5px;
    border-radius: 5px;
    padding: 2px 5px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  @media (max-width: 578px) {
    margin: 0 -25px;
  }
`;

const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }
  thead {
    .ant-table-cell {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  tbody {
    tr > .status {
      padding: 0;
    }
  }
`;

const StyledStatusWrapper = styled.div<{ status: DTO.LoanStatus }>`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 16px;
  background: ${({ status, theme }) => {
    if (status === "defaulted") {
      return theme.colors.bgRed;
    }
    if (status === "paidup") {
      return theme.colors.bgGreen;
    }
    if (status === "due") {
      return theme.colors.bgBlue;
    }
  }};
  color: ${({ status, theme }) => {
    if (status === "defaulted") {
      return theme.colors.redLight;
    }
    if (status === "due") {
      return theme.colors.blue;
    }
    if (status === "paid") {
      return theme.colors.lightGreen;
    }
    if (status === "paidup") {
      return theme.colors.green;
    }
  }};
`;

export const cleanedStatus = (
  value: string,
  activeFilter?: string
): JSX.Element => {
  if (activeFilter && activeFilter !== "all") {
    return cleanedStatus(activeFilter as DTO.LoanStatus);
  }
  if (value === "defaulted") {
    return (
      <StyledStatusWrapper status={value}>
        <InfoCircleOutlined />
        <span>Default</span>
      </StyledStatusWrapper>
    );
  }
  if (value === "due") {
    return (
      <StyledStatusWrapper status={value}>
        <HourglassOutlined />
        <span>Due Today</span>
      </StyledStatusWrapper>
    );
  }
  if (value === "notdue") {
    return (
      <StyledStatusWrapper status={value}>
        <CoffeeOutlined />
        <span>Not Due</span>
      </StyledStatusWrapper>
    );
  }
  if (value === "paid") {
    return (
      <StyledStatusWrapper status={value}>
        <CheckOutlined />
        <span>Paid</span>
      </StyledStatusWrapper>
    );
  }
  if (value === "paidup") {
    return (
      <StyledStatusWrapper status={value}>
        <CheckCircleOutlined />
        <span>Paid Up</span>
      </StyledStatusWrapper>
    );
  }
  return <div />;
};

const LoanListPage: FC<LoanListPageProps> = ({
  loans,
  isMobile,
  loading,
  handleTriggerLoanFetch,
}) => {
  const [activeFilter, setActiveFiter] = useState<DTO.LoanStatus>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredLoans, setFilteredLoans] = useState<DTO.LoanType[]>(loans);
  const [showFilters, setShowFilters] = useState<boolean>(!isMobile);
  const history = useHistory();
  let { url } = useRouteMatch();

  useEffect(() => {
    switch (activeFilter) {
      case "defaulted":
        setFilteredLoans(
          loans.filter((data) =>
            data.monthly_data.some(
              (instance) => getSingleMonthStat(instance) === "defaulted"
            )
          )
        );
        break;
      case "due":
        setFilteredLoans(
          loans.filter((data) =>
            data.monthly_data.some(
              (instance) => getSingleMonthStat(instance) === "due"
            )
          )
        );
        break;
      case "notdue":
        setFilteredLoans(
          loans.filter((data) =>
            data.monthly_data.some(
              (instance) => getSingleMonthStat(instance) === "notdue"
            )
          )
        );
        break;
      case "paid":
        setFilteredLoans(
          loans.filter((data) =>
            data.monthly_data.some(
              (instance) => getSingleMonthStat(instance) === "paid"
            )
          )
        );
        break;
      case "paidup":
        setFilteredLoans(loans.filter((data) => data.status === "paidup"));
        break;
      default:
        setFilteredLoans(loans);
    }
  }, [activeFilter, loans]);

  useEffect(() => {
    setFilteredLoans(
      loans.filter((instance) =>
        instance.client.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, loans]);

  const handleSetActiveFilter = (value?: DTO.LoanStatus) =>
    setActiveFiter(value);

  const handleSetSearchTerm = (value: string) => setSearchTerm(value);

  const handleDateRangeChange = (dateStrings: string[]) => {
    if (dateStrings[0] === "" || dateStrings[1] === "") {
      setFilteredLoans(loans);
      return;
    }
    const loansInRange = loans.filter((LoanInstance) => {
      const hasDateWithinRange = LoanInstance.monthly_data?.findIndex((data) =>
        moment(data.monthly_date).isBetween(dateStrings?.[0], dateStrings?.[1])
      );
      if (hasDateWithinRange >= 0) {
        return true;
      }
      return false;
    });
    setFilteredLoans(loansInRange);
  };

  const handleDelete = (id: string) => {
    handleDeleteLoan(id).then(() => {
      handleTriggerLoanFetch();
    });
  };

  const columns: ColumnsType<object> = useMemo(
    () => [
      {
        title: "Client Name",
        key: "clientName",
        dataIndex: ["client", "name"],
        onCell: (record) => ({
          onClick: () => history.push(`${url}/${(record as DTO.LoanType).client.name}`)
        })
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        className: "status",
        render: (text, record) => cleanedStatus(text, activeFilter),
        onCell: (record) => ({
          onClick: () => history.push(`${url}/${(record as DTO.LoanType).client.name}`)
        })
      },
      {
        title: "",
        key: "delete",
        dataIndex: "",
        className: "delete-action",
        width: 10,
        render: (text, record: any) => (
          <Popconfirm
            placement="top"
            title="Are you sure you want to delete loan?"
            onConfirm={(e) => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilter]
  );

  return (
    <div className="main-content-wrapper">
      <StyledDashboardTtitle>
        <h3 className="title">Loans</h3>
      </StyledDashboardTtitle>

      <StyledWrapper>
        <div
          className="filter-toggle"
          onClick={() => setShowFilters((current) => !current)}
        >
          {showFilters ? "Hide Filters" : "Show Filters"}{" "}
          {showFilters ? <DownOutlined /> : <UpOutlined />}
        </div>
        {showFilters && (
          <FilterPanael
            handleSetActiveFilter={handleSetActiveFilter}
            handleSetSearchTerm={handleSetSearchTerm}
            activeFilter={activeFilter}
            searchTerm={searchTerm}
            handleDateRangeChange={handleDateRangeChange}
          />
        )}
        <hr />
        <StyledTable
          loading={loading}
          dataSource={_.orderBy(
            filteredLoans,
            (val: DTO.LoanType) => moment(val.loan_date),
            ["desc"]
          )}
          columns={columns}
          pagination={{
            pageSize: 20,
            hideOnSinglePage: true,
          }}
        />
      </StyledWrapper>
    </div>
  );
};

export default LoanListPage;
