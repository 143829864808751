import { CloseCircleOutlined } from "@ant-design/icons";
import { Input, List, Typography } from "antd";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { addToBlackList, removeBlacklistName } from "../../helpers/requests";
import { Button } from "../Buttons";
import { StyledDashboardTtitle } from "./LoanForm";

const Wrapper = styled.div`
  button {
    margin: 15px 0;
    height: 30px !important;
    font-size: 1rem;
  }

  .list-item {
    display: flex;
    justify-content: space-between;
  }

  .close-icon {
    cursor: pointer;
  }
`;

interface BlackListProps {
  blackListData: DTO.BlackList[];
  handleTriggerBlackList: () => void;
}

const BlackList: FC<BlackListProps> = ({ blackListData, handleTriggerBlackList }) => {
  const [inputVal, setInputVal] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false)
  const onSubmit = () => {
    if (inputVal === "") {
      return;
    }
    setLoading(true)
    addToBlackList(inputVal).then(() => {
      handleTriggerBlackList()
      setInputVal("")
      setTimeout(() => setLoading(false))
    });
  };

  const deleteName = (name: string) => {
    removeBlacklistName(name, false)
    handleTriggerBlackList()
  }

  return (
    <Wrapper className="main-content-wrapper">
      <StyledDashboardTtitle>
        <h3 className="title">Blacklist</h3>
      </StyledDashboardTtitle>

      <Input
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        placeholder="Add to blacklist"
      />

      <Button  onClick={onSubmit} type="submit" disabled={loading}>
        {loading ? 'Loading..' : 'Blacklist'}
      </Button>

      <List
        bordered
        dataSource={blackListData}
        renderItem={(item) => (
          <List.Item className="list-item">
            <div><Typography.Text mark>[Blacklisted]</Typography.Text> {item.name}</div>
            <CloseCircleOutlined className="close-icon" onClick={() => deleteName(item.name)}/>
          </List.Item>
        )}
      />
    </Wrapper>
  );
};

export { BlackList };
