import React, { FC } from "react";
import { StyledDashboardTtitle } from "./LoanForm";
import styled from "styled-components";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import { useGetTotalValues } from "../../helpers/customHooks";

const { RangePicker } = DatePicker;

interface StatisticsProps {
  loans: DTO.LoanType[];
}

const Wrapper = styled.div`
  .filter-wrap {
    margin: 20px 0;
    min-width: 300px;
  }

  .ant-picker {
    min-width: 300px;
    height: 40px;
    border-radius: 8px;
    border-color: ${({ theme }) => theme.colors.blue};
  }

  .ant-picker-suffix {
    color: ${({ theme }) => theme.colors.blue};
  }

  @media (max-width: 578px) {
    .filter-wrap {
      min-width: unset;
      width: 100%;
    }

    .ant-picker {
      width: -webkit-fill-available;
    }
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 578px) {
    flex-direction: column;
  }
`;

const Cards = styled.div`
  display: flex;
  flex: 1;
  box-shadow: rgb(99 99 99 / 8%) 0px 2px 7px 5px;
  padding: 15px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
  gap: 10px;

  .card-amount {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.blue};
    font-weight: bold;
  }

  .card-text-wrap {
    display: flex;
    flex-direction: column;
  }

  .icon-wrap {
    width: 40px;
    display: flex;
    align-items: center;
  }

  .anticon {
    height: 40px;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    width: 40px;
    border-radius: 10px;
    justify-content: center;
    font-size: 1.2rem;
  }
`;

const Statistics: FC<StatisticsProps> = ({ loans }) => {
  const {
    totalAmountEx,
    totalAmountPaid,
    totalInterestEx,
    totalInterestPaid,
    totleDefaulted,
    updateDate
  } = useGetTotalValues(loans);

  return (
    <Wrapper className="main-content-wrapper">
      <StyledDashboardTtitle>
        <h3 className="title">Statistics</h3>
      </StyledDashboardTtitle>

      <div className="filter-wrap">
        <RangePicker
          defaultValue={[moment().clone().startOf("month"), moment()]}
          format={"YYYY/MM/DD"}
          onChange={(val) => updateDate(val as Moment[])}
        />
      </div>

      <CardsWrapper>
        <Cards>
          <div className="icon-wrap">
            <ArrowsAltOutlined />
          </div>
          <div className="card-text-wrap">
            <div className="card-title">Total Amount Expected</div>
            <div className="card-amount-wrap">
              &#8358; <span className="card-amount">{totalAmountEx.toLocaleString()}</span>
            </div>
          </div>
        </Cards>
        <Cards>
          <div className="icon-wrap">
            <ShrinkOutlined />
          </div>
          <div className="card-text-wrap">
            <div className="card-title">Total Amount Paid</div>
            <div className="card-amount-wrap">
              &#8358; <span className="card-amount">{totalAmountPaid.toLocaleString()}</span>
            </div>
          </div>
        </Cards>
        <Cards>
          <div className="icon-wrap">
            <ArrowsAltOutlined />
          </div>
          <div className="card-text-wrap">
            <div className="card-title">Total Interest Expected</div>
            <div className="card-amount-wrap">
              &#8358; <span className="card-amount">{totalInterestEx.toLocaleString()}</span>
            </div>
          </div>
        </Cards>
        <Cards>
          <div className="icon-wrap">
            <ShrinkOutlined />
          </div>
          <div className="card-text-wrap">
            <div className="card-title">Total Interest Paid</div>
            <div className="card-amount-wrap">
              &#8358; <span className="card-amount">{totalInterestPaid.toLocaleString()}</span>
            </div>
          </div>
        </Cards>
        <Cards>
          <div className="icon-wrap">
            <ShrinkOutlined />
          </div>
          <div className="card-text-wrap">
            <div className="card-title">Total Default</div>
            <div className="card-amount-wrap">
              &#8358; <span className="card-amount">{totleDefaulted.toLocaleString()}</span>
            </div>
          </div>
        </Cards>
      </CardsWrapper>
    </Wrapper>
  );
};

export { Statistics };
