import React, { FC } from "react";
import styled from "styled-components";

type buttonType = "primary" | "secondary";

interface StyledButtonProps {
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?: () => void;
  buttonType?: buttonType;
  disabled?: boolean;
}

const StyledButton = styled.button<{ disabled?: boolean }>`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  font-size: 1.1rem;
  cursor: pointer;
  height: 40px;
  pointer-events: ${({ disabled }) => disabled && 'none'};

  &.secondary {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blue};

    :hover {
      background: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &.primary {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};

    :hover {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const Button: FC<StyledButtonProps> = ({ className, type, onClick, children, buttonType = "primary", disabled }) => (
  <div className={className}>
    <StyledButton className={buttonType} onClick={() => onClick && onClick()} type={type} disabled={disabled}>
      {children}
    </StyledButton>
  </div>
);

export { Button };
