import { message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Main } from "../components/Dashboard/Main";
import { Sidebar } from "../components/Dashboard/Sidebar";
import { getLoanStatus } from "../helpers";
import { getBlacklistNames, getLoans } from "../helpers/requests";

interface DashboardProps {
  isAuth?: boolean;
  handleSignout: () => void;
}

const StyledDashboardWrapper = styled.div`
  display: flex;
`;

const getIsMobile = () => window.innerWidth <= 768;

const Dashboard: FC<DashboardProps> = ({ isAuth, handleSignout }) => {
  const [loans, setLoans] = useState<DTO.LoanType[]>([]);
  const [triggerLoanFetch,  setTriggerLoanFetch] = useState<boolean>(false)
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(getIsMobile());
  const [loading, setLoading] = useState<boolean>(false)
  const [blackList, setBlackList] = useState<DTO.BlackList[]>([])
  const [triggerBlackList, setTriggerBlackList] = useState<boolean>(false)
  const { pathname } = useLocation();

  useEffect(() => {
    getBlacklistNames().then((data) => {
      setBlackList(data)
    })
      .catch((err) => {
      message.error('Oops! unable to fetch blacklist names')
    })
  }, [triggerBlackList])

  useEffect(() => {
    if (isMobile) {
      setSidebarVisible(false);
    } else {
      setSidebarVisible(true);
    }
  }, [pathname, isMobile]);

  useEffect(() => {
    setLoading(true)
    getLoans().then((result) => {
      const loanWithStatus: DTO.LoanType[] = result?.map((instance) => {
        return {
          ...instance,
          status: getLoanStatus(instance)
        }
      }) || [];
      setLoading(false)
      setLoans(loanWithStatus);
    }).catch((err) => {
      setLoading(false)
      message.error("Oops!, Unable to fetch Loans, please try again");
    });
  }, [triggerLoanFetch]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const handleResize = () => {
    setIsMobile(() => getIsMobile());
    setIsMobile(getIsMobile());
  };

  const handleToggleClick = () => setSidebarVisible((current) => !current);
  const handleTriggerLoanFetch = () => setTriggerLoanFetch(current => !current);
  const handleTriggerBlackList = () => setTriggerBlackList((current) => !current)

  return (
    <StyledDashboardWrapper className="page">
      <Sidebar
        className="sidebar"
        isMobile={isMobile}
        isVisible={sidebarVisible}
        handleToggleClick={handleToggleClick}
        handleSignout={handleSignout}
      />
      <Main
        isMobile={isMobile}
        className="main"
        handleToggleClick={handleToggleClick}
        handleTriggerLoanFetch={handleTriggerLoanFetch}
        handleTriggerBlackList={handleTriggerBlackList}
        loans={loans}
        loading={loading}
        setLoans={setLoans}
        blackList={blackList}
      />
    </StyledDashboardWrapper>
  );
};

export { Dashboard };
