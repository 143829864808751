import { SearchOutlined } from "@ant-design/icons";
import { DatePicker, Input, Select } from "antd";
import React, { FC } from "react";
import styled from "styled-components";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface FilterPanaelProps {
  activeFilter?: DTO.LoanStatus;
  handleSetActiveFilter: (filterVal?: DTO.LoanStatus) => void;
  searchTerm: string;
  handleSetSearchTerm: (value: string) => void;
  handleDateRangeChange: (dateStrings: string[]) => void;
}

const StyledSearchInput = styled(Input)`
  height: 35px;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-width: 200px;

  input {
    font-size: 1.125rem;
    padding-left: 10px;
    border: 0;
    height: -webkit-fill-available;
    flex: 1;

    :focus {
      outline: none;
    }
  }

  svg {
    font-size: 15px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  flex-wrap: wrap;

  input {
    font-size: 1rem;
  }
`;

const StyledSelect = styled(Select)`
  height: 35px;
  flex: 1;
  border-radius: 5px;
  min-width: 200px;

  .ant-input-affix-wrapper {
    border-radius: 5px;
  }

  .ant-select-selector {
    height: 35px;
  }

  @media (max-width: 578px) {
    
  }
`;

const FilterPanael: FC<FilterPanaelProps> = ({
  activeFilter,
  handleDateRangeChange,
  handleSetActiveFilter,
  handleSetSearchTerm,
  searchTerm,
}) => {

  return (
    <StyledWrapper className="filter-wrapper">
      <StyledSearchInput
        prefix={<SearchOutlined />}
        placeholder="Search by client name"
        value={searchTerm}
        onChange={(e) => handleSetSearchTerm(e.target.value)}
      />

      <StyledSelect
        placeholder="Filter By"
        onChange={(value, option) =>
          handleSetActiveFilter(value as DTO.LoanStatus)
        }
        value={activeFilter}
        defaultValue="all"
      >
        <Option key="all" value="all">
          All
        </Option>
        <Option key="defaulted" value="defaulted">
          Default
        </Option>
        <Option key="paid" value="paid">
          Paid
        </Option>
        <Option key="paidup" value="paidup">
          Paidup
        </Option>
        <Option key="due" value="due">
          Due
        </Option>
        <Option key="notdue" value="notdue">
          Not Due
        </Option>
      </StyledSelect>
      <RangePicker onChange={(_, formatString) => handleDateRangeChange(formatString)} inputReadOnly />
    </StyledWrapper>
  );
};

export default FilterPanael;
