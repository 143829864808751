import moment from "moment";

const getLoanStatus = (loan: DTO.LoanType): DTO.LoanStatus => {
  const { monthly_data } = loan;
  const todayDate = moment().format("MM/DD/YY");

  const currentMonthPayment = monthly_data.find((instance) =>
    moment(instance.monthly_date).isSame(todayDate, "month")
  );
  if (currentMonthPayment) {
    return getSingleMonthStat(currentMonthPayment);
  }

  const monthOfInterest = monthly_data.findIndex(({ monthly_date, payed }) => {
    // Get the first month that is unpaid and is within current month
    return (
      !payed &&
      (moment(monthly_date).diff(todayDate, "days") <= 0 ||
        moment(monthly_date).isSame(todayDate, "month"))
    );
  });

  if (monthOfInterest >= 0) {
    const todayDate = moment().format("MM/DD/YY");
    const { monthly_date } = monthly_data[monthOfInterest];
    if (moment(monthly_date).diff(todayDate, "days") > 0) {
      // has not reached date of payment yet
      return "notdue";
    }
    if (moment(monthly_date).diff(todayDate, "days") === 0) {
      // today is payment date
      return "due";
    }
    if (moment(monthly_date).diff(todayDate, "days") < 0) {
      // has exceeded payment date
      return "defaulted";
    }
  } else {
    const unpaidMonths = monthly_data.filter((value) => !value.payed);
    if (unpaidMonths.length) {
      return "paid";
    } else {
      return "paidup";
    }
  }
  return "notdue";
};

const getSingleMonthStat = (monthData: DTO.TenorType): DTO.LoanStatus => {
  const { monthly_date, payed } = monthData;
  const todayDate = moment().format("MM/DD/YY");

  if (payed) {
    return "paid";
  }

  if (moment(monthly_date).diff(todayDate, "days") > 0) {
    // has not reached date of payment yet
    return "notdue";
  }

  if (moment(monthly_date).diff(todayDate, "days") < 0) {
    // has exceeded payment date
    return "defaulted";
  }

  if (moment(monthly_date).diff(todayDate, "days") === 0) {
    // today is payment date
    return "due";
  }
  return "defaulted";
};

const numberWithCommas = (x: string) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const removeDecimal = (val?: string | number) => {
  if (!val) return ''
  return String(val).split('.')[0]
}

const formattedNumber = (val?: number) => {
  if (!val) return ''
  const noDecimal = removeDecimal(String(val))
  return numberWithCommas(noDecimal)
}

export {
  getLoanStatus,
  getSingleMonthStat,
  removeDecimal,
  formattedNumber
};
