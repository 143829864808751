import React from "react";
import styled from "styled-components";
import InvoicePrinting from "../assets/printing_invoices.svg";
import { LoginOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const StyledWrapper = styled.div`
  min-height: 100vh;
  padding: 0 50px;
  display: flex;
  gap: 30px;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 576px) {
    padding: 0 30px;
    .right {
      display: none;
    }
    .left {
      flex: 1;
    }
  }
`;

const Left = styled.div`
  .title {
    font-size: 5rem;
    font-weight: bold;
    line-height: 60px;
  }

  .sub-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 40px;
  }

  .text-wrap {
    h1 {
      color: ${({ theme }) => theme.colors.blue};
      margin: 0;
    }
    h3 {
      color: ${({ theme }) => theme.colors.dark};
      margin: 0;
    }
    h1,
    h3 {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 575.98px) {
    .title {
      font-size: 2rem;
    }
    .sub-title {
      font-size: 1rem;
    }
  }
  @media (min-width: 576.98px) and (max-width: 991.98px) {
    .title {
      font-size: 2.5rem;
    }
    .sub-title {
      font-size: 1.5rem;
    }
  }
`;

const Button = styled.div`
  height: 52px;
  font-size: 1rem;
  border: 1px solid #6c63ff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: fit-content;
  border-radius: 30%;
  cursor: pointer;

  .anticon {
    margin-right: 5px;
  }

  :hover {
    background: #dddffb;
  }
`;

const Right = styled.div`
  align-items: flex-start !important;

  img {
    max-width: 40vw;
  }
`;

const WelcomeScreen = () => {
  const history = useHistory();

  const handleGotoDashboard = () => {
    history.push("/dashboard");
  }

  return (
    <StyledWrapper>
      <Left className="left">
        <div className="text-wrap">
          <h1 className="title">Hello...</h1>
          <h3 className="sub-title">
            Welcome to Compassionate Bakers' Dashboard
          </h3>
          <Button onClick={() => handleGotoDashboard()}>
            <LoginOutlined /> Proceed to Dashboard
          </Button>
        </div>
      </Left>
      <Right className="right">
        <img src={InvoicePrinting} alt="Invoice prinitin" />
      </Right>
    </StyledWrapper>
  );
};

export { WelcomeScreen };
