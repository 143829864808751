import { message, List, Typography } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { formattedNumber, getSingleMonthStat, removeDecimal } from "../../helpers";
import { updateLoan } from "../../helpers/requests";
import { StyledDashboardTtitle } from "./LoanForm";
import { MonthlyPaymentCard } from "./MonthlyPaymentCard";

interface LoanDetailProps {
  loans: DTO.LoanType[];
  setLoans: React.Dispatch<React.SetStateAction<DTO.LoanType[]>>;
  handleTriggerLoanFetch: () => void;
}

const ItemList = styled.div`
  display: flex;
  gap: 10px;

  label {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blue3};
  }

  span {
    font-weight: bold;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 10px;
`;

const LoanList = styled(List)`
  .list-item {
    cursor: pointer;
  }
`;

const LoanDetail: FC<LoanDetailProps> = ({
  loans,
  setLoans,
  handleTriggerLoanFetch,
}) => {
  const [loan, setLoan] = useState<DTO.LoanType>();
  const [myLoans, setMyLoans] = useState<DTO.LoanType[]>([]);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { clientName } = useParams<{ clientName: string }>();

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line eqeqeq
      setLoan(loans.find((instance) => instance?.id == id));
      return;
    }
    setLoan(loans.find((instance) => instance.client.name === clientName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loans, id]);

  useEffect(() => {
    setMyLoans(
      loans.filter(
        (val) => val.client.name === loan?.client.name && val.id !== loan.id
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan]);

  const handlePay = async (stat: boolean, id: string) => {
    if (!loan) {
      return;
    }

    const res = await updateLoan(id, stat)
    if (res) {
      handleTriggerLoanFetch();
    } else {
      message.error(
        "Oops!, Unable to update payment status, please try again"
      );
    }
  };

  return loan ? (
    <div className="main-content-wrapper">
      <StyledDashboardTtitle>
        <h2 className="title">{clientName}</h2>
      </StyledDashboardTtitle>

      <ItemList>
        <label>Date of Loan</label>{" "}
        <span>{moment(loan?.loan_date).format("dddd, MMMM D YYYY")}</span>
      </ItemList>
      <ItemList>
        <label>Amount of Loan </label>
        <span>&#8358;{formattedNumber(loan?.loan_amount)}</span>
      </ItemList>
      <ItemList>
        <label>Loan Tenor</label> <span>{removeDecimal(loan?.loan_tenor)}</span>
      </ItemList>
      <ItemList>
        <label>Phone Number</label> <span>{loan?.client.phone_number}</span>
      </ItemList>
      <ItemList>
        <label>Loan Net Pay</label> <span>&#8358;{formattedNumber(loan?.net_pay)}</span>
      </ItemList>
      <ItemList>
        <label>Total Capital</label> <span>&#8358;{formattedNumber(loan?.total_capital)}</span>
      </ItemList>
      <ItemList>
        <label>Total Interest</label> <span>&#8358;{formattedNumber(loan?.total_interest)}</span>
      </ItemList>
      <ItemList>
        <label>Loan Status</label> <span>{loan?.status}</span>
      </ItemList>
      <ItemList>
        <label>Staff Name</label> <span>{loan?.staff_name}</span>
      </ItemList>

      <CardsWrapper>
        {loan?.monthly_data.map((instance, index) => (
          <MonthlyPaymentCard
            id={instance.id || ''}
            paid={instance.payed}
            month={instance?.monthly_date}
            capital={instance?.monthly_capital}
            interest={instance?.monthly_interest}
            handlePay={handlePay}
            status={getSingleMonthStat(instance)}
            date={moment(instance.monthly_date).format("dddd, MMMM D YYYY")}
            index={index}
          />
        ))}
      </CardsWrapper>

      <h3 style={{ marginTop: "20px" }}>
        Other Loans by <strong>{loan.client.name}</strong>
      </h3>
      <LoanList
        bordered
        dataSource={myLoans}
        renderItem={(item) => (
          <List.Item
            className="list-item"
            onClick={() =>
              history.replace(
                `/dashboard/loans/${(item as DTO.LoanType).client.name}/${
                  (item as DTO.LoanType).id
                }`
              )
            }
          >
            <div>
              <Typography.Text mark>
                [{(item as DTO.LoanType).status}]
              </Typography.Text>{" "}
              {moment((item as DTO.LoanType).loan_date).format(
                "dddd, MMMM D YYYY"
              )}
            </div>
          </List.Item>
        )}
      />
    </div>
  ) : (
    <></>
  );
};

export { LoanDetail };
