import { MenuOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from 'styled-components'

interface MainProps {
  className?: string
  showToggler?: boolean
  onMenuClick?: () => void
}

export const StyledBrandedTitle = styled.span`
  font-size: 1.5rem;
  font-family: 'Pacifico', cursive;
  cursor: pointer;
`

const StyledNavWrap = styled.div`
  position: fixed;
  width: -webkit-fill-available;
  background-color: ${({ theme }) => theme.vars.bgColor};
  height: 60px;
  box-shadow: inset 0px -1px 0px #f3f3f4;
  padding: 0 50px;
  font-weight: bold;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .toggler {
    cursor: pointer;
  }

  @media (max-width: 576px) {
    padding: 0 30px;
  }
`

const Navbar: FC<MainProps> = ({ className, showToggler, onMenuClick }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/')
  }

  return (
    <StyledNavWrap className={`${className} navbar`} >
      <StyledBrandedTitle onClick={() => handleClick()} className="title">C. Bakers</StyledBrandedTitle>

      {showToggler && <MenuOutlined className="toggler" onClick={() => onMenuClick && onMenuClick()} />}
    </StyledNavWrap>
  )
};

export { Navbar };
