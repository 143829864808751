import React, { FC } from "react";
import styled from "styled-components";
import { SidebarListItem } from "./SidebarListItem";
import { AppstoreFilled, CalendarOutlined, CloseOutlined, HolderOutlined, LogoutOutlined, WarningOutlined } from "@ant-design/icons";
import { StyledBrandedTitle } from "../Navbar";
import { useHistory, useLocation } from "react-router-dom";

interface SidebarProps {
  className?: string;
  isVisible: boolean;
  isMobile?: boolean;
  handleToggleClick: () => void;
  handleSignout: () => void;
}

const StyledSidebarWrap = styled.div<{ isVisible?: boolean, isMobile?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  width: 340px;
  padding: 30px;
  position: fixed;
  height: 100vh;
  display: ${({ isVisible }) => isVisible ? 'block' : 'none'};

  .menu-text {
    padding-left: 30px;
  }

  .nav-wrapper {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    align-items: center;
    display: ${({ isMobile }) => isMobile ? 'flex' : 'none'};
    box-shadow: inset 0px -1px 0px #f3f3f4;
  }

  @media (max-width: 768px) {
    width: 70% !important;
    padding: 10px;
    z-index: 1000;
  }

  @media (max-width: 992px) {
    width: 200px;
  }
`;

const menuItems = [
  {
    title: "Loan Form",
    key: "",
    icon: <AppstoreFilled />,
  },
  {
    title: "Loan List",
    key: "loans",
    icon: <HolderOutlined />,
  },
  {
    title: "Statistics",
    key: "statistics",
    icon: <CalendarOutlined />,
  },
  {
    title: "Black List",
    key: "blacklist",
    icon: <WarningOutlined />,
  },
  {
    title: "Sign out",
    key: "signout",
    icon: <LogoutOutlined />,
  },
];

export const StyledTitle = styled.h3`
  margin: 0;
`;

const MenuListWrap = styled.div`
  margin-top: 30px;
`;

const Sidebar: FC<SidebarProps> = ({
  isVisible,
  isMobile,
  handleToggleClick,
  handleSignout,
}) => {
  const history = useHistory()
  const { pathname} = useLocation()
  const onRouteToHome = () => {
    history.push('/')
  }

  const getIsActive = (key: string) => {
    if (pathname === `/dashboard${key ? `/${key}` : ''}`) {
      return true
    }
    return false
  }

  return (
    <StyledSidebarWrap isVisible={isVisible}  isMobile={isMobile}>

      {isMobile && <div className="nav-wrapper"><CloseOutlined onClick={() => handleToggleClick()} /></div>}
      {!isMobile && <StyledBrandedTitle onClick={onRouteToHome}>C. Bakers</StyledBrandedTitle>}
      
      <MenuListWrap>
        {menuItems.map(({ title, icon, key }, index) => (
          <SidebarListItem
            id={key}
            key={index}
            title={title}
            icon={icon}
            isActive={getIsActive(key)}
            handleSignout={handleSignout}
          />
        ))}
      </MenuListWrap>
    </StyledSidebarWrap>
  );
};

export { Sidebar };
