import { createGlobalStyle, css } from "styled-components";
import { ThemeType } from "./theme";

const FontCss = css`
  ${({ theme }) =>
    theme.fontFaces.map(
      (fontFace: any) => `
        @font-face {
          font-family: '${fontFace.family}';
          src:  url('./assets/fonts/${
            fontFace.fileName
          }.eot?#iefix') format('embedded-opentype'),
                url('./assets/fonts/${fontFace.fileName}.woff') format('woff'),
                url('./assets/fonts/${
                  fontFace.fileName
                }.woff2') format('woff2'),
                url('./assets/fonts/${
                  fontFace.fileName
                }.ttf') format('truetype'),
          font-weight: ${fontFace.weight || "nomal"};
          font-style: ${fontFace.style || "nomal"};
        }
      `
    )}
`;

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
    /* @import '~antd/dist/antd.css'; */
    ${FontCss}
    html {
      -webkit-tap-highlight-color:transparent;
    }
    body {
        font-family: 'Lato', sans-serif;
        margin: 0;
        user-select: none;
    }

    .page {
      min-height: 100vh;
    }

    .ant-form-item-explain-error {
      color: ${({ theme }) => theme.colors.redLight};
      margin-top: 10px;
    }

    .ant-message {
      position: fixed;
      top: 20px;
      padding: 10px;
      width: -webkit-fill-available;
      display: flex;
      justify-content: center;

      .ant-message-notice-content {
        background-color: ${({ theme }) => theme.colors.white};
        padding: 10px;
      }

      .ant-message-success {
        .anticon {
          color: ${({ theme }) => theme.colors.green};
        }
      }
    }

    @media (max-width: 578px) {
      .ant-picker-panels {
        flex-direction: column;
      }
    }
`;
export { GlobalStyle };
