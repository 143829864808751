import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { addNewLoan } from "../../helpers/requests";
import { Button } from "../Buttons";

interface LoanFormProps {
  handleTriggerLoanFetch: () => void;
  blackList: DTO.BlackList[];
}

interface CapitalIntType {
  capitalName: string;
  interestName: string;
  initialIndex: number;
}

export const StyledDashboardTtitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;

  .title,
  .subtitle {
    text-align: center;
  }

  .title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.blue};
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.blue3};
  }

  @media (min-width: 768px) {
    .title {
      font-size: 2.3rem;
    }
  }
`;

const StyledeForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;

  .submit-btn {
    button {
      width: -webkit-fill-available;
    }
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .ant-form-item-label {
      display: flex;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  input {
    height: 40px;
    border-radius: 8px;
    border: 0;
    outline: none;
    font-size: 1rem;
    padding: 1px 20px;
    color: ${({ theme }) => theme.colors.dark1};
    margin-top: 10px;
    width: -webkit-fill-available;

    ::placeholder {
      color: ${({ theme }) => theme.colors.dark1};
    }

    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.dark1};
    }

    ::-ms-input-placeholder {
      color: ${({ theme }) => theme.colors.dark1};
    }
  }

  &.grouped-input-wrap {
    padding: 10px;
    row-gap: 0px;
    background: #9797a587;
    border-radius: 10px;
  }

  .ant-input-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 10px;

      .ant-form-item {
        flex: 1;
      }
    }
  }
`;

const StyledAddIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  span {
    cursor: pointer;
    background: #c4c5cd;
    padding: 5px;
    border-radius: 15px;
  }

  &.close-icon-wrap {
    .anticon {
      padding: 0;
    }
  }
`;

const isBlacklisted = (name: string, blackList: DTO.BlackList[]) =>
  blackList.some((instance) => instance.name === name);

const LoanForm: FC<LoanFormProps> = ({ handleTriggerLoanFetch, blackList }) => {
  console.log({blackList})
  const [form] = Form.useForm();
  const [maxInitialIndex, setMaxInitialIndex] = useState<number>(0)
  const [monthlyCapInt, setMonthlyCapInt] = useState<CapitalIntType[]>([
    {
      capitalName: "monthlyInterest_0",
      interestName: "monthlycapital_0",
      initialIndex: maxInitialIndex,
    },
  ]);

  useEffect(() => {
    console.log({ monthlyCapInt })
  }, [monthlyCapInt])

  message.config({
    duration: 3,
  });

  const handleNewMonthlyCap = () => {
    setMonthlyCapInt((current) => [
      ...current,
      {
        capitalName: `monthlyInterest_${current.length}`,
        interestName: `monthlycapital_${current.length}`,
        initialIndex: maxInitialIndex + 1,
      },
    ]);
    setMaxInitialIndex(current => current + 1)
  };

  const handleRemoveMonthlyCap = (index: number) => {
    setMonthlyCapInt((current) => {
      current.splice(index, 1);
      return [...current];
    });
    
  };

  const getMonthlyValuesObj = () => {
    const formValues = form.getFieldsValue();

    return monthlyCapInt.map((_, index) => {
      return {
        monthly_date: formValues[`monthlyDate_${index + 1}`],
        monthly_capital: formValues[`monthlycapital_${index + 1}`],
        monthly_interest: formValues[`monthlyInterest_${index + 1}`],
        payed: false,
      };
    });
  };

  const handleFormFinish = () => {
    const clientName = form.getFieldValue("name");
    if (isBlacklisted(clientName, blackList)) {
      message.error("This client has been Blacklisted");
      return;
    }

    addNewLoan({
      ...form.getFieldsValue(),
      number_of_paymentmonths: monthlyCapInt.length,
      monthly_data: getMonthlyValuesObj(),
      status: "notdue",
    })
      .then(() => {
        message.success("Your Form has been successfuly submited");
        form.resetFields();
        handleTriggerLoanFetch();
      })
      .catch((error) => {
        message.error("Oops!, Something went wrong, please try again");
      });
  };

  return (
    <div className="main-content-wrapper">
      <StyledDashboardTtitle>
        <h3 className="title">Loan Form</h3>
      </StyledDashboardTtitle>

      <StyledeForm form={form} onFinish={handleFormFinish}>
        <StyledFormItem
          label="Client Name"
          name="name"
          rules={[{ required: true, message: "Please input client's name!" }]}
        >
          <Input type="text" placeholder="Enter client name" />
        </StyledFormItem>

        <StyledFormItem
          label="Date of loan"
          name="loan_date"
          rules={[{ required: true, message: "Please input loan date!" }]}
        >
          <Input type="date" />
        </StyledFormItem>

        <StyledFormItem
          label="Loan Amout"
          name="loan_amount"
          rules={[{ required: true, message: "Please input loan amount!" }]}
        >
          <Input type="number" placeholder="Enter loan amount" />
        </StyledFormItem>

        <StyledFormItem
          label="Loan Tenor"
          name="loan_tenor"
          rules={[{ required: true, message: "Please input loan tenor!" }]}
        >
          <Input type="number" placeholder="Enter loan tenor" />
        </StyledFormItem>

        <StyledFormItem
          label="Client number"
          name="phone_number"
          rules={[
            {
              required: true,
              message: "Please input client's phone number!",
            },
          ]}
        >
          <Input type="number" placeholder="Enter client phone number" />
        </StyledFormItem>

        <StyledFormItem
          label="Net Pay"
          name="net_pay"
          rules={[{ required: true, message: "Please input Net Pay!" }]}
        >
          <Input type="number" placeholder="Enter net pay" />
        </StyledFormItem>

        <StyledFormItem label="Total Capital" name="total_capital">
          <Input type="number" />
        </StyledFormItem>

        <StyledFormItem label="Total Interest" name="total_interest">
          <Input type="number" />
        </StyledFormItem>

        {monthlyCapInt.map(({ initialIndex }, index) => (
          <StyledFormItem className="grouped-input-wrap">
            <StyledAddIconWrapper
              onClick={() => handleRemoveMonthlyCap(index)}
              className="close-icon-wrap"
            >
              <CloseOutlined />
            </StyledAddIconWrapper>
            <Input.Group compact>
              <StyledFormItem
                name={`monthlyDate_${initialIndex + 1}`}
                rules={[
                  { required: true, message: "Please input mothly date!" },
                ]}
              >
                <Input type="date" />
              </StyledFormItem>
              <StyledFormItem
                name={`monthlycapital_${initialIndex + 1}`}
                rules={[
                  {
                    required: true,
                    message: "Please input mothly capital!",
                  },
                ]}
              >
                <Input type="number" placeholder="capital" />
              </StyledFormItem>
              <StyledFormItem
                name={`monthlyInterest_${initialIndex + 1}`}
                rules={[
                  {
                    required: true,
                    message: "Please input mothly interesst!",
                  },
                ]}
              >
                <Input type="number" placeholder="interest" />
              </StyledFormItem>
            </Input.Group>
          </StyledFormItem>
        ))}

        <StyledAddIconWrapper onClick={() => handleNewMonthlyCap()}>
          <span>
            Add Monthly Capital
            <PlusOutlined />
          </span>
        </StyledAddIconWrapper>

        <StyledFormItem
          label="Staff Name"
          name="staff_name"
          rules={[{ required: true, message: "Please input staff's name!" }]}
        >
          <Input type="text" placeholder="Enter staff name" />
        </StyledFormItem>

        <Button type="submit" className="submit-btn" buttonType="primary">
          Submit
        </Button>
      </StyledeForm>
    </div>
  );
};

export default LoanForm;
