import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Navbar } from "../Navbar";
import { BlackList } from "./BlackList";
import { LoanDetail } from "./LoanDetail";
import LoanForm from "./LoanForm";
import LoanListPage from "./LoanListPage";
import { Statistics } from "./Statistics";

interface MainProps {
  className?: string;
  handleToggleClick: () => void;
  handleTriggerLoanFetch: () => void;
  handleTriggerBlackList: () => void;
  isMobile: boolean;
  loans: DTO.LoanType[];
  loading: boolean;
  blackList: DTO.BlackList[];
  setLoans: React.Dispatch<React.SetStateAction<DTO.LoanType[]>>;
}

const StyledMainWrapper = styled.div<{ isMobile?: boolean }>`
  flex: 1;
  padding: 0 30px 20px 30px;
  margin-left: ${({ isMobile }) => (isMobile ? "0" : "360px")};

  .main-content-wrapper {
    margin-top: 100px;
  }

  .dashboard-nav {
    padding: 0;
  }

  @media (max-width: 992px) {
    margin-left: ${({ isMobile }) => (isMobile ? "0" : "260px")};

    .main-content-wrapper {
      margin-top: 100px;
    }
  }
`;

const Main: FC<MainProps> = ({
  handleToggleClick,
  handleTriggerLoanFetch,
  handleTriggerBlackList,
  setLoans,
  isMobile,
  loans,
  loading,
  blackList,
}) => {
  let { path } = useRouteMatch();

  return (
    <>
      {isMobile && (
        <Navbar
          showToggler
          className="dashboard-nav"
          onMenuClick={handleToggleClick}
        />
      )}

      <StyledMainWrapper isMobile={isMobile}>
        <Switch>
          <Route
            path={`${path}/loans/:clientName/:id?`}
            render={() => (
              <LoanDetail
                setLoans={setLoans}
                loans={loans}
                handleTriggerLoanFetch={handleTriggerLoanFetch}
              />
            )}
          />
          <Route
            exact
            path={`${path}/loans`}
            render={() => (
              <LoanListPage
                isMobile={isMobile}
                loans={loans}
                loading={loading}
                handleTriggerLoanFetch={handleTriggerLoanFetch}
              />
            )}
          />
          <Route
            exact
            path={path}
            render={() => (
              <LoanForm handleTriggerLoanFetch={handleTriggerLoanFetch} blackList={blackList} />
            )}
          />
          <Route
            exact
            path={`${path}/blacklist`}
            render={() => <BlackList handleTriggerBlackList={handleTriggerBlackList} blackListData={blackList} />}
          />
          <Route
            exact
            path={`${path}/statistics`}
            render={() => <Statistics loans={loans} />}
          />
        </Switch>
      </StyledMainWrapper>
    </>
  );
};

export { Main };
