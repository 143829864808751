import styled from "styled-components";
import { Form, Input } from "antd";
import { MailFilled } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { FC } from "react";

interface LoginProps {}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginCard = styled.div`
  border-radius: 9px;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 400px;
  width: 450px;
  text-align: center;
  padding: 50px 30px;
  box-shadow: rgb(99 99 99 / 8%) 0px 2px 8px;

  .card-title {
    font-size: 2rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }

  .card-sub-title {
    color: ${({ theme }) => theme.colors.gray1};
  }

  .input-wrap {
    display: flex;
    gap: 30px;
    flex-direction: column;
    margin-top: 50px;

    .ant-input-affix-wrapper {
      display: flex;
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.vars.borderColor};
      padding: 0 10px;
      display: flex;
      align-items: center;

      .anticon-mail {
        margin-left: 10px;
      }

      .ant-input-password-icon {
        padding-left: 10px;
      }

      .ant-input {
        border: none;
        outline: none;
        flex: 1;
        height: 40px;
        font-size: 0.9rem;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.white} !important;
      }
    }

    button {
      border-radius: 5px;
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      font-size: 1.1rem;
      color: ${({ theme }) => theme.colors.blue};
      cursor: pointer;

      :hover {
        background: ${({ theme }) => theme.colors.blue};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  button {
    height: 40px;
  }
`;

interface LoginProps {
  handleSignIn: (prop: DTO.EmailPasswordType) => void;
}

const Login: FC<LoginProps> = ({ handleSignIn }) => {
  const [form] = useForm();

  const onSubmit = () => {
    const authVals = form.getFieldsValue();
    handleSignIn(authVals)
  };

  return (
    <StyledWrapper className="page">
      <LoginCard>
        <div className="card-title">Welcome Back</div>
        <p className="card-sub-title">
          Enter your credentials to access dashboard
        </p>

        <div className="input-wrap">
          <Form form={form} onFinish={onSubmit}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "please provide a valid email" },
              ]}
            >
              <Input
                placeholder="Enter your Email"
                suffix={<MailFilled className="mail-icon" />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please provide valid password" },
              ]}
            >
              <Input.Password
                visibilityToggle
                placeholder="Enter your password"
              />
            </Form.Item>
            <button type="submit">Sign In</button>
          </Form>
        </div>
      </LoginCard>
    </StyledWrapper>
  );
};

export { Login };
