import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Card, Popconfirm } from "antd";
import moment from "moment";
import React, { FC } from "react";
import styled from "styled-components";
import { formattedNumber } from "../../helpers";
import { cleanedStatus } from "./LoanListPage";

interface MonthlyPaymentCardProps {
  month: string;
  paid?: boolean;
  capital?: number;
  interest?: number;
  handlePay: (stat: boolean, id: string) => void;
  status: DTO.LoanStatus;
  date: string;
  index: number;
  id: string;
}

const StyledCard = styled(Card)<{ status: DTO.LoanStatus }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px;
  min-width: 200px;
  flex: 1;
  border: none;
  min-width: 200px;
  flex: 1;
  border-radius: 10px;
  color: white;
  box-shadow: rgb(99 99 99 / 8%) 0px 2px 9px 7px;
  background: ${({ status, theme }) => {
    if (status === "defaulted") {
      return theme.colors.bgRed;
    }
    if (status === "paidup") {
      return theme.colors.bgGreen;
    }
    if (status === "due") {
      return theme.colors.bgBlueLight;
    }
  }};
  color: ${({ status, theme }) => {
    if (status === "defaulted") {
      return theme.colors.redLight;
    }
    if (status === "due" || status === "notdue") {
      return theme.colors.blue;
    }
    if (status === "paid") {
      return theme.colors.lightGreen;
    }
    if (status === "paidup") {
      return theme.colors.green;
    }
  }};

  .ant-card-head {
    min-height: 0;
    border: none;
    padding: 0;
  }

  .ant-card-head-title {
    padding: 0;
    font-weight: bold;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    > div {
      padding: 0;
      margin-bottom: 15px;
      justify-content: center;
    }
  }
`;
const PaymentButton = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.dark};
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const MonthlyPaymentCard: FC<MonthlyPaymentCardProps> = ({
  month,
  paid,
  status,
  date,
  handlePay,
  capital,
  interest,
  index,
  id,
}) => {
  return (
    <StyledCard
      title={moment(month).format("MMMM")}
      bordered={false}
      status={status}
    >
      <p>
        <strong>Capital: </strong>
        {formattedNumber(capital)}
      </p>
      <p>
        <strong>Interest: </strong>
        {formattedNumber(interest)}
      </p>
      <p>{date}</p>
      {cleanedStatus(status)}
      {paid ? (
        <Popconfirm
          placement="top"
          title="Sure you want to reverse payment status?"
          onConfirm={() => handlePay(false, id)}
          okText="Yes"
          cancelText="No"
        >
          <PaymentButton>
            Not Paid <CloseCircleOutlined />
          </PaymentButton>
        </Popconfirm>
      ) : (
        <Popconfirm
          placement="top"
          title="Are you sure Payment is Confirmed?"
          onConfirm={() => handlePay(true, id)}
          okText="Yes"
          cancelText="No"
        >
          <PaymentButton>
            Confirm Paid <CheckOutlined />{" "}
          </PaymentButton>
        </Popconfirm>
      )}
    </StyledCard>
  );
};

export { MonthlyPaymentCard };
