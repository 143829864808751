const mainTheme = {
  fontFaces: [
    {
      family: 'Lato',
      weight: '400',
      style: 'nomal',
      fileName: 'Lato-Regular',
    },
    {
      family: 'Lato',
      weight: '700',
      style: 'bold',
      fileName: 'Lato-Bold',
    },
    {
      family: 'Lato',
      weight: '700',
      style: 'italic',
      fileName: 'Lato-Bold',
    },
    {
      family: 'Pacifico',
      weight: '400',
      style: 'normal',
      fileName: 'Pacifico-Regular',
    },
  ],
  vars: {
    textColorDark: '#1c1b1e',
    textColorLight: '#abadb1',
    borderColor: '#9ba2b0',
    bgColor: '#f8fafb',
  },
  colors: {
    white: '#ffffff',
    lightGreen: '#58cfa4',
    bgGreen: "#c6eed438",
    green: '#58cfa4',
    red: '#b43247',
    redLight: '#ff2400',
    bgRed: "#fdf7f7",
    red20: '#fef7f7',
    red50: '#e2204d',
    gray1: '#888e95',
    gray2: '#646466',
    gray3: '#959aa1',
    dark: '#1b161e',
    dark1: '#07074e9e',
    dark3: '#07074e45',
    lightBlue: '#f8fafb',
    blue: "#171f60",
    blue1: "#425ebd",
    blue2: "#425ebd21",
    blue3: "#7b8eb5",
    blue4: "#42537f",
    blue5: "#171f6078",
    bgBlue: "#171f601c",
    bgBlueLight: "#171f6000",
    yellow: "#FFF44F",
    bgYellow: "#fff44f21",
  }
}

export type ThemeType = typeof mainTheme;

export default mainTheme