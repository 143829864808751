import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Navbar } from "../components/Navbar";
import { WelcomeScreen } from "../components/WelcomScreen";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Login } from "../components/Login";
import { Dashboard } from "./Dashboard";
import { handleLogin, handleLogout } from "../helpers/requests";


const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.vars.bgColor};
  display: flex;
  flex-direction: column;
`;

const Layout = () => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const { pathname } = useLocation();
  const history = useHistory();

  const handleSignIn = async ({ email, password }: DTO.EmailPasswordType) => {
    const res = await handleLogin({ email, password })
    setIsAuth(res)
  };

  const handleSignout = () => {
    handleLogout().then(() => {
      setIsAuth(false)
      history.push("/");
    })
  };

  useEffect(() => {
    if (localStorage.getItem('cbakers_token')) {
      setIsAuth(true)
      history.push("/dashboard");
    }
  }, [isAuth, history]);

  return (
    <StyledWrapper>
      {!pathname?.includes("dashboard") && <Navbar />}

      <Switch>
        <Route path="/" component={WelcomeScreen} exact />
        <Route
          path="/login"
          render={() => <Login handleSignIn={handleSignIn} />}
          exact
        />
        <Route
          path="/dashboard"
          render={() => (isAuth ? <Dashboard handleSignout={handleSignout} /> : <Redirect to="/login" />)}
        />
      </Switch>
    </StyledWrapper>
  );
};

export { Layout };
