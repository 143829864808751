import { message } from "antd";

interface HeaderType {
  [key: string]: string
}

const baseURL = "https://api.compassionatebackers.com";

const hashToken = (token: string | null) => {
  if (token) {
    return token.split('').reverse().join('')
  }
}

const makeRequest = async (url: string, method: string, data?: any) => {
  const token = localStorage.getItem('cbakers_token')
  const headers: HeaderType = {
    "Content-Type": "application/json",
    'Accept': "application/json"
  }
  if (token) {
    headers['Authorization'] = `Token ${hashToken(token)}`
  }
  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(data),
  });
  return response.json();
};

const handleLogin = async (authData: {
  email: string;
  password: string;
}) => {
  const url = `${baseURL}/user/login/`;
  const res = await makeRequest(url, "POST", authData)
  const token = res?.token
  if (token) {
    const newToken = hashToken(token)
    localStorage.setItem('cbakers_token', (newToken as string))
    return true
  } else {
    if (res?.message) {
      message.error(res.message);
    }
    return false
  }
};

const handleLogout = async () => {
  localStorage.removeItem('cbakers_token')
}

const getLoans = async (): Promise<DTO.LoanType[] | undefined> => {
  const url = `${baseURL}/loans/`;
  try {
    const loans = await makeRequest(url, 'GET');
    return loans as DTO.LoanType[];
  } catch {
    message.error("Oops!, An error occured please try again");
  }
};

const addNewLoan = async (loan: DTO.LoanType) => {
  const url = `${baseURL}/loans/`;
  return makeRequest(url, "POST", loan)
    .then((data) => true)
    .catch((error) => false);
};

const updateLoan = async (id: string, payed: boolean): Promise<boolean> => {
  const url = `${baseURL}/loans/monthly/update/${id}/`;

  return makeRequest(url, "PATCH", { payed })
    .then((data) => true)
    .catch((error) => false);
};

const removeBlacklistName = async (clientName: string, blacklisted: boolean) => {
  const url = `${baseURL}/clients/${clientName}/`;

  return makeRequest(url, 'PATCH', { is_blacklisted: blacklisted, name: clientName })
    .then((data) => true)
    .catch((error) => false);
};

const addToBlackList = async (clientName: string) => {
  const url = `${baseURL}/clients/blacklist/`

  return makeRequest(url, 'POST', { is_blacklisted: true, name: clientName })
    .then((data) => true)
    .catch((error) => false);
}

const getBlacklistNames = async () => {
  const url = `${baseURL}/clients/blacklist/`;
  const data = await makeRequest(url, 'GET');
  return data;
};

const handleDeleteLoan = async (id: string) => {
  const url = `${baseURL}/loans/${id}/`;

  return makeRequest(url, "DELETE")
    .then((data) => true)
    .catch((error) => false);
};

export {
  handleLogout,
  handleLogin,
  getLoans,
  addNewLoan,
  updateLoan,
  addToBlackList,
  getBlacklistNames,
  removeBlacklistName,
  handleDeleteLoan,
};
